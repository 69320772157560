import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import '../styles/global.css'
const Companies = [
  {
    company_name: 'Boom Supersonic',
    description: 'Supersonic low CO2 passenger aircraft',
    website_url: 'http://www.boomsupersonic.com',
    image_placeholder: '/images/boom.jpg',
  },
  {
    company_name: 'ChromaTan',
    description: 'Continuous purification of biopharmaceuticals',
    website_url: 'http://www.chromatan.com',
    image_placeholder: '/images/chroma.jpg',
  },
  {
    company_name: 'Chromatic 3D Materials',
    description: '3D printing of industrial seals and gaskets',
    website_url: 'http://www.c3dmaterials.com',
    image_placeholder: '/images/chromatic.jpg',
  },
  {
    company_name: 'DermaSensor',
    description: 'AI-based diagnosis of skin cancer',
    website_url: 'http://www.dermasensor.com',
    image_placeholder: '/images/dermaSensor.jpg',
  },
  {
    company_name: 'Eko Devices',
    description: 'Digital stethoscopes for heart monitoring',
    website_url: 'http://www.ekohealth.com',
    image_placeholder: '/images/eko.jpg',
  },
  {
    company_name: 'Gila Therapeutics',
    description: 'Oral GLP-1 drug for weight reduction',
    website_url: 'http://www.gilatherapeutics.com',
    image_placeholder: '/images/gila.jpg',
  },
  {
    company_name: 'Guardian Medical',
    description: 'Sterile packaging for orthopedic implantables',
    website_url: 'http://www.guardianmedicalusa.com',
    image_placeholder: '/images/guardian-medical.jpg',
  },
  {
    company_name: 'Inversa Leathers',
    description: 'High fashion leathers from invasive fish and reptiles',
    website_url: 'http://www.inversaleathers.com',
    image_placeholder: '/images/INVERSA_Leathers_Logo.jpg',
  },
  {
    company_name: 'JASCI',
    description: 'Integrated warehouse management software',
    website_url: 'http://www.jascicloud.com',
    image_placeholder: '/images/jasci.jpg',
  },
  {
    company_name: 'Lingrove',
    description: 'Eco laminates from renewable grasses',
    website_url: 'http://www.lingrove.com',
    image_placeholder: '/images/lingrove.jpg',
  },
  {
    company_name: 'Natilus',
    description: 'Next generation drone-based air freighters',
    website_url: 'http://www.natilus.co',
    image_placeholder: '/images/natilus.jpg',
  },
  {
    company_name: 'Niron',
    description: 'Industrial magnet manufacturing with no rare earth materials',
    website_url: 'http://www.nironmagnetics.com',
    image_placeholder: '/images/nironMagnetics.jpg',
  },
  {
    company_name: 'Reprise Biomedical',
    description: 'Resorbable patches for wound closure',
    website_url: 'http://www.reprisebio.com',
    image_placeholder: '/images/reprise-biomedical.jpg',
  },
  {
    company_name: 'Rhaeos',
    description: 'Wearable fluid monitor for hydrocephalus',
    website_url: 'http://www.rhaeos.com',
    image_placeholder: '/images/rhaes.jpg',
  },
  {
    company_name: 'StabiLux Biosciences',
    description: 'Intracellular dyes for medical research',
    website_url: 'http://www.stabiluxbiosciences.com',
    image_placeholder: '/images/stabilux.jpg',
  },
  {
    company_name: 'Watershed Therapeutics',
    description: 'Extended protection against UTIs',
    website_url: 'http://www.watershedtx.com',
    image_placeholder: '/images/watershed.jpg',
  },
]

const Years = [
  {
    vintage1: '2015',
    number2: '24',
    toDate3: '$4.3',
    actual4: '$2.2',
    unrealized5: '$15.3',
    projected6: '$17.5',
    fairValue7: '$5.7',
    grossTVP8: '1.8X',
    gross9: '4.0X',
    net10: '3.2X',
  },
  {
    vintage1: '2019',
    number2: '30',
    toDate3: '$5.8',
    actual4: '$0.1',
    unrealized5: '$17.2',
    projected6: '$17.3',
    fairValue7: '$7.1',
    grossTVP8: '1.3X',
    gross9: '3.0X',
    net10: '2.4X',
  },
  {
    vintage1: '2021',
    number2: '30',
    toDate3: '$5.0',
    actual4: '$0.0',
    unrealized5: '$16.4',
    projected6: '$16.4',
    fairValue7: '$6.5',
    grossTVP8: '1.3X',
    gross9: '3.3X',
    net10: '2.6X',
  },
  {
    vintage1: `LCOF⁴`,
    number2: '3',
    toDate3: '$0.3',
    actual4: '$0.0',
    unrealized5: '$0.8',
    projected6: '$0.8',
    fairValue7: '$0.3',
    grossTVP8: '1.0X',
    gross9: '2.5X',
    net10: '2.0X',
  },
  {
    vintage1: '',
    number2: '87',
    toDate3: '$15.4',
    actual4: '$2.3',
    unrealized5: '$49.8',
    projected6: '$52.0',
    fairValue7: '$19.6',
    grossTVP8: '1.4X',
    gross9: '3.4X',
    net10: '2.5X',
  },
]
const IndexPage = () => (
  <div className=" max-w-screen-lg mx-auto">
    <div className=" w-full min-h-min bg-lateralOrange mb-10">
      <StaticImage
        src="../images/lateral.jpg"
        alt="A bridge"
        className="w-full"
      />
    </div>
    <div className="w-full grid grid-cols-12 justify-between  md:grid-cols-w">
      <div className="grid grid-flow-col grid-cols-8 items-center col-span-5">
        <div className=" col-span-4 md:col-span-2">
          <img className="p-0 m-0 w-40 md:w-24" src="../images/icon_only.gif" />
        </div>
        <di className="col-span-6">
          <p className="font-black p-0 m-0 text-2xl">Lateral Capital®</p>{' '}
          <p className="p-0 m-0 text-xl">Opportunity Fund, LP</p>
        </di>
      </div>
      <div className=" bg-lateralOrange text-white font-bold p-4 col-span-5 col-start-8 flex-row justify-between ">
        <div>
          <h3 className="m-0 text-white font-bold text-2xl">Fund Overview</h3>
          <h3 className="m-0 text-white font-bold text-2xl">
            For Accredited Investors
          </h3>
        </div>
        <div>
          <p className="p-0 m-0">January 2024</p>
        </div>
      </div>
    </div>
    <div className="px-4 lg:px-0 mx-auto text-justify">
      <p className="text-justify py-6 ">
        Headquartered in Sarasota, Florida, Lateral Capital is a Micro Venture
        Capital Limited Partnership for accredited investors. Over the last nine
        years, our Limited Partnerships have invested in 87 companies across 21
        states. As of January 31, 2024, projected gross returns for these funds
        are about 3.4X invested capital (MOIC), ahead of the 2.2X to 2.6X
        “industry average” for investments in Early Stage Companies.
      </p>
    </div>
    <div className="m-4 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block  py-2 align-top sm:px-6 ">
          <div className=" shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="divide-y divide-gray-300  ">
              <thead className="bg-lateralOrange ">
                <tr className="[&>*]:border [&>*]:border-white">
                  <th
                    scope="col"
                    className=" pl-4 pr-3 border border-white text-left text-sm font-semibold text-white sm:pl-6 align-middle"
                  >
                    Vintage
                  </th>
                  <th
                    scope="col"
                    className="px-3 border border-white  text-center text-sm font-semibold text-white align-middle"
                  >
                    Number of Investments
                    <br /> To Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 border border-white  text-center text-sm font-semibold text-white align-middle"
                  >
                    Amount Invested To Date ($ Million)
                  </th>
                  <th
                    scope="col"
                    colSpan="3"
                    className=" text-center border border-white text-sm p-0 font-semibold bg-gray-400 text-white align-middle"
                  >
                    <div className="bg-lateralOrange p-2">
                      Amount Returned ($ Million)
                    </div>
                    <div className="flex">
                      <div className=" flex-1  text-center text-sm py-[.54rem] font-semibold bg-gray-400 text-white border border-white align-middle">
                        Actual As Of{' '}
                        <span className="text-xs">Dec. 31, 2023</span>
                      </div>
                      <div className="flex-1  text-center text-sm py-[.54rem] font-semibold bg-gray-400 text-white border border-white align-middle">
                        Unrealized Projected<sup>1</sup>
                      </div>
                      <div className="flex-1  text-center text-sm py-[.54rem] font-semibold bg-gray-400 text-white border border-white align-middle">
                        Projected Total<sup>1</sup>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-3  text-center text-sm font-semibold text-white align-middle"
                  >
                    Estimated
                    <br /> Fair Value
                    <br /> of Active
                    <br />
                    <span className="text-xs">($ Million)</span>
                  </th>
                  <th
                    scope="col"
                    className="px-3  text-center text-sm font-semibold text-white align-middle border border-white"
                  >
                    Gross TVPI
                    <br />
                    (MOIC)<sup>2,3</sup>
                  </th>
                  <th
                    scope="col"
                    colSpan="2"
                    className="text-center text-sm font-semibold bg-lateralOrange text-white p-0  border border-white "
                  >
                    <div>
                      <div className="flex align-middle justify-center pb-3">
                        <span>
                          Projected Returns (MOIC)<sup>1,3</sup>
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <div className=" font-semibold bg-gray-400 text-white border border-white py-[.7rem]">
                          Gross
                        </div>
                        <div className="font-semibold bg-gray-400 text-white border border-white py-[.7rem]">
                          Net
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-gray-200 ">
                {Years.map((year, i) => (
                  <tr className="tableRow" key={i}>
                    <td className="whitespace-nowrap py-4 pl-4 text-center pr-3 text-sm font-medium text-gray-900 sm:pl-6 border border-white ">
                      {year.vintage1}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 border border-white">
                      {year.number2}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 border border-white">
                      {year.toDate3}
                    </td>
                    <td className="w-24 whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 border border-white">
                      {year.actual4}
                    </td>
                    <td className="w-24 whitespace-nowrap py-4 pl-4 text-center pr-3 text-sm font-medium text-gray-900 sm:pl-6 border border-white">
                      {year.unrealized5}
                    </td>
                    <td className="w-24 whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 border border-white">
                      {year.projected6}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 border border-white">
                      {year.fairValue7}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 border border-white">
                      {year.grossTVP8}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 border border-white">
                      {year.gross9}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 border border-white">
                      {year.net10}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className=" md:p-0 py-5 bg-white  text-[10px] ">
            <div className=" p-0">
              <p className=" text-gray-600 p-0 m-0">
                <strong className="underline">
                  1. Definition of Projected Returns
                </strong>{' '}
                – Gross Returns do not include Management Fees, the cost of
                operating the Funds or Carried Interest. Net Returns are
                estimated based on projected Management Fees and costs.
                Projections are based on a variety of inputs believed to be
                reliable but are strictly not guaranteed. Columns may not add
                due to rounding.
              </p>
            </div>
            <div className=" p-0 m-0">
              <p className=" text-gray-600 p-0">
                <strong className="underline">2. Definition of TVPI</strong> –
                TVPI or Total Value to Paid-In Capital is the ratio of the
                current value of remaining investments within a fund, plus the
                total value of all distributions to date, relative to the total
                amount of capital paid into the fund to date, with any
                recallable distributions included in the numerator of this ratio
                and any reinvested capital (resulting from recallable
                distributions) incuces in the denominator
              </p>
            </div>
            <div className=" p-0 m-0">
              <p className=" text-gray-600 p-0">
                <strong className="underline">
                  3. Projection of Gross/Net Returns
                </strong>{' '}
                – The GP’s projection of what the Funds will return when all
                investments are realized. This is based on actuals to date,
                projected outcomes, and projections of expenses to the end of
                Fund life.
              </p>
            </div>
            <div className=" p-0 m-0">
              <p className=" text-gray-600 p-0">
                <strong className="underline">
                  4. Initial LCOF Investments
                </strong>{' '}
                – We have already made the first three investments in LCOF:
                <br /> –
                <span className="underline font-bold">
                  {' '}
                  Commonwealth Fusion Systems
                </span>
                - Building a device to generate electric power using nuclear
                fusion.
                <br /> – <span className="underline font-bold">
                  {' '}
                  Earthgrid
                </span>{' '}
                - Uses plasma technology to bore tunnels, even through solid
                rock, for sale/lesse to electric utilities.
                <br /> –
                <span className="underline font-bold">
                  {' '}
                  Imperium Technologies
                </span>{' '}
                - Wireless steam traps to reduce industrial energy use in 20MM
                U.S. locations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="max-w-screen-xl mx-auto px-4 lg:px-0 ">
      <p className="text-justify">
        <span className="underline font-bold">Overview Of Offering</span> –
        Lateral Capital (LC) is offering interests in its fourth syndicated
        Limited Partnership, the Lateral Capital Opportunity Fund, LP (LCOF).
        LCOF will likely invest primarily in companies where Lateral Capital
        Funds have positions. We believe these companies are somewhat de-risked
        and that they have potential for a profitable exit in the next 5 to 7
        years. As examples, we would consider additional investment in the
        companies listed below:
      </p>
    </div>
    <div className=" max-w-screen-xl">
      <div className="grid p-6 md:p-4 grid-cols-2 md:grid-cols-2 gap-4 md:gap-20">
        {Companies.map((company, i) => (
          <div
            key={i}
            className="grid grid-cols-1 grid-rows-2 md:grid-rows-1 md:grid-cols-2 gap items-center border border-gray-500 p-4 rounded-md"
          >
            <div
              key={company.company_name}
              className="bg-white row-start-2 md:row-start-1 text-center md:text-left text-wrap w-full p-4"
            >
              <h3 className="m-0 p-0 underline whitespace-nowrap ">
                {company.company_name}
              </h3>
              <p>{company.description}</p>
              <a
                href={company.website_url}
                target="_blank"
                className="w-full text-xs "
                rel="noreferrer"
              >
                {company.website_url}
              </a>
            </div>
            <div className="flex justify-center row-start-1 md:row-start-1 ">
              <img
                src={company.image_placeholder}
                alt="A bridge"
                className=" w-56"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className=" p-4 lg:p-0 max-w-screen-xl [&>p]:text-justify py-8">
      <div className="p-4 py-8">
        <span className="underline font-bold">
          Background On Lateral Capital Limited Partnership
        </span>
        – Since 2015, we have invested in Early Stage Companies led by paranoid
        optimists of impeccable character. We look to invest in companies with:
      </div>
      <ul className="custom-list-style py-8 [&>li]:py-2">
        <li className="pl-2 py-2 ">
          Completed products or services, a compelling user benefit and ideally
          one customer.
        </li>
        <li className="pl-2 py-2">
          Proprietary technology with issued or filed patents – to protect the
          company while it grows.
        </li>
        <li className="pl-2 py-2">
          Logical strategic buyers who will need to buy the company – in the
          next 5-7 years.
        </li>
        <li className="pl-2 py-2">
          Business models which lead to cashflow breakeven – while the company
          waits for a buyer.
        </li>
        <li className="pl-2 py-2">
          Contribute to the Greater Good – businesses which have the potential
          to benefit society.
        </li>
      </ul>
      <p className="py-4 ">
        Within these criteria, Lateral Capital has made investments in a wide
        range of categories – from health care to industrial products; from
        specialty B2B software to a therapy for Alzheimer’s. We do not invest in
        distribution-driven categories like food and beverage or in businesses
        which are location-dependent. Similarly, we do not invest in companies
        engaged in online gambling, computer games or marijuana.
      </p>
      <p className="py-4">
        Most of our investments have been made alongside Angel groups which
        “source” most of our investments. These include Band of Angels (Palo
        Alto), Seraph Group (Atlanta), Golden Seeds (New York), Tamiami Angels
        (Naples), Central Texas Angels (Austin) and TCA Venture Group (Southern
        California). We have also invested in companies which have presented at
        SXSW and Angel Capital Association events.
      </p>
      <p>
        Lateral Capital is run by long-time Procter & Gamble executive and
        former Pillsbury CEO, John Lilly. He is joined by Tim Kost, as Managing
        Director and CFO, and by experienced Broker/Dealer assistant Michelle
        Ballentine. Limited Partners include CEOs, CFOs, investment bankers and
        others across the U.S.
      </p>
      <p className="py-4">
        <span className="underline font-bold">Current Situation</span> – Rapid
        increases in interest rates have greatly reduced availability of private
        capital to all Venture-backed companies. Investments in Early Stage
        Companies declined for six straight quarters through the end of 2023.
        This situation makes for a great opportunity to invest in Early Stage
        Companies which have short-term capital needs – but excellent
        longer-term potential.
      </p>
      <p className="py-4 text-justify">
        <span className="underline font-bold">
          Lateral Capital Opportunity Fund Offering
        </span>
        – The Lateral Capital Opportunity Fund will invest up to $10MM in 15-40
        Early Stage Companies, depending on the amount of capital we raise. Many
        of these companies will be add-on investments of the 50+ active
        companies of our prior funds. These are businesses we already know
        something about, in which we have increasing confidence and which need
        more capital to get to an exit. On a select basis, we may also invest in
        other Early Stage opportunities, particularly in companies which operate
        in spaces adjacent to our existing investments.
      </p>
      <p className="text-justify py-4">
        To kick off the Fund, an affiliate of the General Partner will
        contribute investments in three companies where investments have
        recently been made. These will be contributed to the Opportunity Fund at
        cost, plus reimbursable transaction expenses of no more than $5,000 per
        company:
      </p>
      <div className="grid grid-rows-3 md:grid-rows-1 md:grid-cols-3 gap-4">
        <div className="grid grid-flow-row grid-rows-3  border border-gray-500 rounded-md p-4">
          <div className="flex justify-center row-span-2">
            <img src="../images/common.jpg" alt="A bridge" className=" w-56" />
          </div>
          <div className="h-max">
            <span className="font-bold underline">
              Commonwealth Fusion Systems
            </span>{' '}
            – Building a device to generate electric power using nuclear fusion.
            Their first power unit is already under construction in Devens, MA.
          </div>
        </div>
        <div className="grid grid-flow-row grid-rows-3 border border-gray-500 rounded-md p-4">
          <div className="flex justify-center row-span-2">
            <img
              src="../images/earthgrid.jpg"
              alt="A bridge"
              className=" w-56"
            />
          </div>
          <div className="h-max">
            <span className="font-bold underline">Earthgrid</span> – Uses plasma
            technology to bore tunnels, even through solid rock. Earthgrid will
            build tunnels for sale or lease to utilities, primarily for
            electricity transmission.
          </div>
        </div>
        <div className="grid grid-flow-row grid-rows-3 border border-gray-500 rounded-md p-4">
          <div className="flex justify-center row-span-2">
            <img
              src="../images/imperium.jpg"
              alt="A bridge"
              className=" w-56"
            />
          </div>
          <div className="h-max">
            <span className="font-bold underline">Imperium Technologies</span> –
            Imperium Technologies – Produces wireless controlled steam traps
            designed to reduce energy costs in industrial facilities. There are
            about 20MM steam trap devices in the U.S.
          </div>
        </div>
      </div>
      <p className="py-8">
        <span className="underline font-bold">Details Of The Offer</span> – Here
        are the key elements of how the Fund will operate:
      </p>
      <ul className=" custom-list-style py-8 [&>li]:py-2">
        <li className=" pl-2">
          Investment minimum of $100,000, to be called in three tranches over
          three years, with 40% due at the first close of $2MM. We have about
          $1MM in immediate opportunities across 7-10 companies.
        </li>
        <li className=" pl-2">
          A seven year fund life (three years invest/four years monitoring),
          with extensions by vote of the Limited Partners. This reflects our
          plan to invest where there are rapid sale opportunities.
        </li>
        <li className=" pl-2">
          The General Partner will contribute 10% of the Fund, with a minimum of
          $550,000. Fees will be “industry standard” (2% Management Fee plus 20%
          Carried Interest) and reasonable expenses.
        </li>
      </ul>
      <p className="py-8">
        <span className=" font-bold underline">
          Additional Information Available
        </span>
        – We have prepared several introductory presentations which are
        downloadable as .pdfs by clicking the boxes below. These are fairly
        standard for investments in Venture Capital funds, but they are the
        official documents which govern the Fund. If you have questions, we
        encourage you to discuss them with us or your Legal/Financial Advisor.
      </p>
      <div className="flex gap-4 flex-col justify-center w-full [&>*]:w-full [&>*>*]:w-full max-w-screen w-screen-md mx-auto">
        <div className="">
          <a
            href="https://cdn.sanity.io/files/xzv7us05/production/8c528203411441aca936868d8ea8cbf209bf2bfa.pdf"
            target="_blank"
            className="no-underline rounded-md bg-lateralOrange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-lateralOrange   block text-center"
            rel="noreferrer"
          >
            Lateral Capital Fund History
          </a>
        </div>
        <div>
          <a
            href="https://cdn.sanity.io/files/xzv7us05/production/5b4345397dc756aabaebe6c4525207e4c9c2e17b.pdf"
            target="_blank"
            className="no-underline rounded-md bg-lateralOrange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 block hover:text-lateralOrange text-center"
            rel="noreferrer"
          >
            Lateral Capital Opportunity Fund – Fund Overview
          </a>
        </div>
        <div>
          <a
            href="https://cdn.sanity.io/files/xzv7us05/production/8827720b4e6b74733a0c094536e7a8891b0936b8.pdf"
            target="_blank"
            className="no-underline rounded-md bg-lateralOrange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 block hover:text-lateralOrange  text-center"
            rel="noreferrer"
          >
            Lateral Capital Opportunity Fund – Private Placement Memorandum
          </a>
        </div>
        <div>
          <a
            href="https://cdn.sanity.io/files/xzv7us05/production/cecae9d863abedcff3dc0193fb06eaa148da39e4.pdf"
            target="_blank"
            className="no-underline rounded-md bg-lateralOrange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 block hover:text-lateralOrange  text-center"
            rel="noreferrer"
          >
            Lateral Capital Opportunity Fund – Limited Partnership Agreement
          </a>
        </div>
        <div>
          <a
            href="https://cdn.sanity.io/files/xzv7us05/production/53a86db40b1ebad22f0976ed7c685a84492c37d2.pdf"
            target="_blank"
            className="no-underline rounded-md bg-lateralOrange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 block hover:text-lateralOrange  text-center"
            rel="noreferrer"
          >
            Lateral Capital Opportunity Fund – Frequently Asked Questions
          </a>
        </div>
        <div>
          <a
            href="https://cdn.sanity.io/files/xzv7us05/production/4328da597044f2e9356be522080430e1041cff2e.pdf"
            target="_blank"
            className="no-underline rounded-md bg-lateralOrange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 block hover:text-lateralOrange  text-center"
            rel="noreferrer"
          >
            Lateral Capital Opportunity Fund – Summary of Terms
          </a>
        </div>
        <div>
          <a
            href="https://cdn.sanity.io/files/xzv7us05/production/18409964296319577abcc54607845602bf697c0f.pdf"
            target="_blank"
            className="no-underline rounded-md bg-lateralOrange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 block hover:text-lateralOrange  text-center"
            rel="noreferrer"
          >
            Lateral Capital Opportunity Fund – Important Liability Disclaimer
          </a>
        </div>
      </div>
      <div className="[&>p]:text-justify px-4 lg:px-0 py-8">
        <p className="text-justify ">
          <span className="font-bold underline">
            Third Party Verification Process
          </span>
          – To comply with SEC regulations, all Prospective Investors will need
          to provide third party verification that they are accredited. The
          general requirements are that an investor have $200,000 in annual
          income ($300,000 for those filing jointly) with reasonable
          expectations to continue at this level. Alternatively, investors could
          be deemed accredited if they have $1,000,000 in assets beyond their
          principal residence. Before we can accept your investment, we will
          need your lawyer, Financial Advisor or CPA to confirm your accredited
          investor status in writing. This is surprisingly simple. In the
          signature packet below, there is a DocuSign form for you to fill in
          and sign. When it is returned to us, we will send it on to the Advisor
          you name to be completed. Importantly, we do not need any data from
          you like tax returns, etc. We just need the signature of someone who
          has knowledge of your financial condition and can confirm that with us
          on a “blind” basis.
        </p>
        <p className="py-8 text-justify">
          <span className="font-bold underline">Signing Up With The Fund</span>–
          The process for joining the Lateral Capital Opportunity Fund is
          straightforward. Open the Signature Packet by clicking the box below
          and fill them in. When the Third Party Accreditation is received back
          from your Advisor, we will countersign the documents and return them
          to you. At that time, you will also receive a Capital Call for the
          first 40% of your commitment.
        </p>
      </div>
      <a
        href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=8917b82d-c082-46df-a168-66c079cedcc5&env=na3&acct=c89ecaf8-0617-418e-a96d-7c33c9fa923e&v=2"
        className="no-underline"
      >
        <div className=" border white-list-style border-black text-white max-w-prose mx-auto p-6 rounded-md bg-lateralOrange text-center">
          <span className="underline underline-white font-bold color-white font-black text-center">
            Signature Packet For Lateral Capital Opportunity Fund, L.P.
          </span>
          <ol className=" no-underline text-white list-decimal-white max-w-md mx-auto text-justify">
            <li className="no-underline">
              1: Subscription Agreement and Investor Questionnaire
            </li>
            <li className="no-underline">
              2: Signature Page for Limited Partnership Agreement
            </li>
            <li className="no-underline ">3: W-9 Form</li>
            <li className="no-underline">
              4: Signature Page to Third Party Accredited Verification
            </li>
          </ol>
        </div>
      </a>
      <p className="text-justify py-8">
        <span className="font-bold underline">Next Steps</span> – We are happy
        to set up a Zoom to talk about any aspect of our plans for the Lateral
        Capital Opportunity Fund. Please contact Michelle Ballentine to set up a
        time:
      </p>
      <div className="px-4 w-full lg:px-0 text-center">
        <p>
          Lateral Capital Opportunity Fund, LP ,<br />
          Attention: Michelle Ballentine
          <br />
          1500 State Street, Suite 201, Sarasota, FL 34236
          <br />
          612-330-5055
          <br />
          <a
            href="mailto:michelle.ballentine@lateralcapital.com"
            className=" underline"
          >
            michelle.ballentine@lateralcapital.com
          </a>
          <br />
        </p>
      </div>
    </div>
    <div className="p-10 border border-black m-4 text-xs [&>*]:text-justify prose w-full max-w-[95vw] mx-auto">
      <p className="font-bold text-justify">
        The presentation preceding these pages and any related oral or written
        discussion (variously described as the “Materials,” the “Information” or
        this “Presentation”) is governed by the limitations detailed below.
      </p>
      <p className="text-justify">
        <span className="font-bold underline">Introduction</span> – This
        Presentation was produced by Lateral Capital Management, LLC (LCM) on
        behalf of Lateral Capital Opportunity Fund General Partner, LP (LCOFGP)
        for the purposes of engaging investors in considering an investment in
        Lateral Capital VII, LP (LCOF). While LCM is the author of this
        presentation, the Disclaimers described below apply to all Lateral
        Capital entities, including LCM, Lateral Capital Advisors, LLC and
        individual Lateral Capital funds.
      </p>
      <p className="text-justify">
        <span className="font-bold underline">General Disclaimer</span> – By
        acceptance hereof, you agree that (i) the information contained herein
        may not be used, reproduced or distributed to others, in whole or in
        part, for any other purpose without the prior written consent of LCM;
        (ii) you will keep confidential all information contained herein not
        already in the public domain; (iii) the information contains highly
        confidential and proprietary ‘trade secrets’ (some of which may
        constitute material non-pubvlic information); and (iv) you will only use
        the information contained in this Presentation for informational
        purposes and will not trade in securities on the basis of any such
        information. The presentation and other ancillary documents include
        various examples or subsets of Lateral Capital’s existing and past
        investments. Such information includes, among other things, portfolio
        company valuations and fund performance, which includes Gross MoM and
        Gross IRR, all estimated as of specific dates as indicated. The
        portfolio companies highlighted have been chosen generally for the
        purposes of illustration and not based on performance. Investments in
        other companies may have materially different results. There is no
        assurance that any portfolio companies discussed herein will remain in
        the relevant fund at the time you receive this information.
      </p>
      <p className="text-justify">
        <span className="font-bold underline">
          Important Securities Law Information
        </span>{' '}
        – This Presentation does not constitute an offer to sell or a
        solicitation of an offer to buy any securities and may not be used or
        relied upon in evaluating the merit of investing in LCOF. A confidential
        information memorandum (including any supplements thereto, the “CIM”)
        and definitive documentation relating to LCOF or our investments
        therein, including constitutive documents and subscription documents
        (collectively, the “LCOF Documents”), will be made available and must be
        received by you prior to subscribing for an interest in LCOF. An offer
        or solicitation for LCOF will be made only through the CIM, which will
        contain additional information about the investment objectives, terms
        and conditions of an investment in LCOF and risk disclosures that will
        be important to any investment decision regarding LCOF. The information
        set forth herein is not a part of or supplemental to the CIM, the LCOF
        Documents or any documents ancillary thereto. The information set forth
        herein will be superseded in its entirety by the CIM and the LCOF
        Documents. In the case of any discrepancy between the information
        contained herein and the CIM or the LCOF Documents, the CIM and the LCOF
        Documents will control.
      </p>
      <p className="text-justify">
        <span className="font-bold underline">
          Representations and Warranty
        </span>{' '}
        – No representation or warranty is given in respect of the information
        contained herein, and neither the delivery of this document nor the
        placing of any interests in LCOF will under any circumstances create any
        implication that LCOF has updated the information contained herein.
        Information throughout the Presentation provided by sources other than
        LCOF has not been independently verified. Differences between past
        performance and actual results may be material and adverse. Past
        performance is not necessarily indicative of future results. Unless
        otherwise stated, any reference to MoM and IRR refers to Gross MoM and
        Gross IRR. Gross MoM is calculated on a gross basis and does not reflect
        the effect of fund expenses, taxes borne, or to be borne, by investors
        in LCOF, and would be lower if it did. These materials may include
        comparisons to an index (e.g., S&P 500, Russell 2000 and MSCI World
        Index or other benchmarks for publicly-held securities) against the
        performance of LCOF. The performance of these indexes is provided for
        reference only. LCOF differs from an index in that, among other things,
        it is actively invested and is subject to various fees and other
        expenses. Investors cannot invest directly in the index.
      </p>
      <p className="text-justify">
        <span className="font-bold underline">
          Risk of Loss From Investments
        </span>
        – Any projections, estimates, forecasts, targets, prospects, returns
        and/or opinions contained in these materials involve elements of
        subjective judgment and analysis and are based upon the best judgment of
        LCM as of the date of these materials. LCM does not take responsibility
        for such projections, estimates, forecasts, targets, prospects, returns
        and/or opinions. Any valuations, forecasts, targets, opinions or
        projections expressed in these materials are subject to change without
        notice and may differ or be contrary to opinions expressed by others. No
        representation or warranty is given as to the achievement or
        reasonableness of, and no reliance should be placed on, any valuations,
        forecasts, targets, estimates or opinions described in these materials.
      </p>
      <p className="text-justify">
        <span className="font-bold underline">Forward-Looking Statements</span>{' '}
        – All statements in this presentation (and oral statements made
        regarding the subjects of this presentation) other than historical facts
        are forward- looking statements, which rely on a number of estimates,
        projections and assumptions concerning future events. Such statements
        are also subject to a number of uncertainties and factors outside LCM’s
        control. Such factors include, but are not limited to, uncertainty
        regarding and changes in global economic or market conditions, including
        those affecting the industries of Lateral Capital portfolio companies,
        and changes in U.S. or foreign government policies, laws, regulations
        and practices. Opinions expressed are current opinions as of the date of
        this presentation. Should LCOF’s estimates, projections and assumptions
        or these other uncertainties and factors materialize in ways that LCM
        did not expect, actual results could differ materially from the
        forward-looking statements in this presentation, including the
        possibility that investors may lose a material portion of the amounts
        invested. While LCM believes the assumptions underlying these
        forwardlooking statements are reasonable under current circumstances,
        investors should bear in mind that such assumptions are inherently
        uncertain, subjective and that past or projected performance is not
        necessarily indicative of future results. No representation or warranty,
        express or implied, is made as to the accuracy or completeness of the
        information in this presentation, and nothing shall be relied upon as a
        promise or representation as to the performance of any investment.
        Investors are cautioned not to place undue reliance on such
        forward-looking statements and should rely on their own assessment of an
        investment.
      </p>
      <p className="text-justify">
        <span className="font-bold underline">
          Information Regarding Determination of Targeted Returns
        </span>{' '}
        – LCOF believes the targeted returns for certain investments included in
        these materials are reasonable based on a combination of factors,
        including the performance of previous Early Stage investments, Lateral
        Capital’s historical track record and LCM’s assessment of prevailing
        market conditions and investment opportunities. However, LCOF’s targeted
        returns for certain investments are not a predication or projection of
        actual investment results and there can be no assurance that the
        targeted returns will be achieved. Investors should also be aware that
        relatively high targeted returns, such as those included in these
        materials, entail concomitantly greater risks of adverse and volatile
        investment results. The targeted return of any individual investment can
        be lower or higher, depending on the nature of any individual
        investment. LCM’s evaluation of a proposed investment is based, in part,
        on LCM’s internal analysis and evaluation of the investment and on
        numerous investment-specific assumptions that may not be consistent with
        future market conditions and that may significantly affect actual
        investment results. No representation or warranty is made as to the
        reasonableness of the assumptions made or that all assumptions used in
        calculating the targeted returns have been stated or fully considered.
        LCOF’s ability to achieve investment results consistent with its
        targeted returns depends significantly on a number of factors in
        addition to the accuracy of such assumptions. Such factors include,
        among other things, LCM’s ability to execute its investment strategy
        successfully and an assumption that investments will be exited in less
        than ten years after investment, which cannot be assured. Investments
        may be held substantially shorter or longer than LCM’s exit assumption,
        which can significantly affect the investment’s performance. The past
        performance of Lateral Capital Funds is not necessarily indicative of
        future performance and there can be no assurance that LCOFI will succeed
        in achieving the targeted returns discussed herein. Such targets may be
        adjusted in light of available opportunities or changing market
        conditions.
      </p>
      <p className="text-justify">
        <span className="font-bold underline">
          This Presentation is Not Financial or Investment Advice
        </span>{' '}
        – The Materials do not constitute investment advice, recommendations or
        solicitations to purchase or sell securities mentioned herein, if any.
        LCM is not, and does not provide services as an investment advisor,
        investment analyst, broker, broker-dealer, market-maker, investment
        banker or underwriter. Information in this presentation, including
        forecast financial information, if any, is for information purposes only
        and should not be considered as advice or a recommendation with respect
        to holding, purchasing or selling securities or other financial products
        or instruments and does not take into account the reader’s particular
        investment objectives, financial situation or needs. Before acting on
        any information, readers should consider the appropriateness of the
        information and should seek independent financial advice. The contents
        hereof should not be construed as investment, legal, tax or other advice
        and you should consult your own advisers as to legal, business, tax and
        other related matters concerning an investment in LCOF. Unless otherwise
        noted, the information contained herein has been compiled as of January
        2024 and there is no obligation to update the information provided
        herein.
      </p>
      <p className="text-justify">
        <span className="font-bold underline">
          Licensed Content and Ownership
        </span>{' '}
        – Content related to One Page Strategic Planning®, OGSP®, Five Questions
        | One Page® Success Planning™ and 5Q|1P® are used under license from One
        Page Solutions, LLC. Other trademarks used in this presentation are the
        property of their respective owners.
      </p>
    </div>
  </div>
)

export default IndexPage
